<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <MainForm :form="form" :purpose="purpose">
            <template v-slot:main>
              <b-form-group id="input-group-room_id">
                <label for="input-room_id">Ruang Yang Akan Dipinjam:</label>
                <treeselect
                  v-model="form.room_id"
                  :multiple="false"
                  placeholder="Ruang yang akan Dipinjam"
                  :options="room_id"
                />
                <small class="text-danger">{{ error.room_id }}</small>
              </b-form-group>

              <b-form-group id="input-group-necessity">
                <label for="input-necessity"
                  >Keperluan:</label
                >
                <b-form-textarea
                  id="input-necessity"
                  v-model="form.necessity"
                  placeholder="Keperluan"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{
                  error.necessity
                }}</small>
              </b-form-group>

              <b-form-group id="input-group-end_time">
                <label for="input-start_time"
                  >Waktu Mulai:</label
                >
                <b-form-datepicker
                  id="input-start_time"
                  v-model="form.start_time"
                  placeholder="Waktu Mulai"
                ></b-form-datepicker>
                <small class="text-danger">{{ error.start_time }}</small>
              </b-form-group>

              <b-form-group id="input-group-end_time">
                <label for="input-end_time"
                  >Waktu Selesai:</label
                >
                <b-form-datepicker
                  id="input-end_time"
                  v-model="form.end_time"
                  placeholder="Waktu Selesai"
                ></b-form-datepicker>
                <small class="text-danger">{{ error.end_time }}</small>
              </b-form-group>

              <b-form-group id="input-group-teacher_mentor">
                <label for="input-teacher_mentor">Dosen Pembimbing:</label>
                <treeselect
                  v-model="form.teacher_mentor"
                  :multiple="false"
                  placeholder="Dosen Pembimbing"
                  :options="teacher_mentor"
                />
                <small class="text-danger">{{ error.teacher_mentor }}</small>
              </b-form-group>
            </template>
          </MainForm>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/letters/space-loan-application/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
    currentPhoto: String,
  },
  components:{
    MainForm
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        semester_id: "",
        academic_mentor: "",
        document_type: "",
        necessity: "",
        sign_image: "",
      },
      current_photo: "/img/default/blank.jpg",
      default_photo: "/img/default/blank.jpg",
      semesters: [],
      teacher_mentor: [],
      room_id: [],
    };
  },
  methods: {
    async getRoomOption() {
      //ganti
      let response = await module.setTreeSelectRoom(
        "api/rooms",
        "?role_id=1"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.room_id = response.data;
        this.room_id.unshift({
          label: "Pilih Ruang",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getTeacherOption() {
      //ganti
      let response = await module.setTreeSelect(
        "api/users",
        "?role_id=1"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teacher_mentor = response.data;
        this.teacher_mentor.unshift({
          label: "Pilih Dosen",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getSemesterActive() {
      let response = await module.get("api/semesters/now");
      // If Data Not Found
      if (response != null) {
        this.form.semester_id = response.id;
      }
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.sign_image = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },
    validationCheck() {
      let status = true;
      // if (
      //   this.form.hasOwnProperty("interest") == false ||
      //   this.form.interest == ""
      // ) {
      //   this.error.interest = "Kolom kepeminatan tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.interest = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution1") == false ||
      //   this.form.institution1 == ""
      // ) {
      //   this.error.institution1 = "Kolom instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address1") == false ||
      //   this.form.institution_address1 == ""
      // ) {
      //   this.error.institution_address1 =
      //     "Kolom alamat instansi 1 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address1 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution2") == false ||
      //   this.form.institution2 == ""
      // ) {
      //   this.error.institution2 = "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution2 = "";
      // }
      // if (
      //   this.form.hasOwnProperty("institution_address2") == false ||
      //   this.form.institution_address2 == ""
      // ) {
      //   this.error.institution_address2 =
      //     "Kolom instansi 2 tidak boleh kosong";
      //   status = false;
      // } else {
      //   this.error.institution_address2 = "";
      // }
      // if (this.purpose == "add") {
      //   if (
      //     this.form.hasOwnProperty("sign_image") == false ||
      //     this.form.sign_image == ""
      //   ) {
      //     this.error.sign_image = "Gambar TTD belum dipilih";
      //     status = false;

      //     this.$root.$emit("validationImageTrue")
      //     console.log('send emit')
      //   } else {
      //     this.error.sign_image = "";
      //     this.$root.$emit("validationImageFalse")
      //   }
      // }

      return status;
    },
    async formOnsubmit() {
      if (this.validationCheck() == false) {
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("Validation Error");
      }

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/letters/space-loan-application/list");
      }
    },
  },
  mounted() {
    this.getSemesterOption();
    this.getSemesterActive();
    this.getTeacherOption();
    this.getRoomOption();
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;
        this.current_photo = this.form.sign;
        this.default_photo = this.form.sign;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 180px !important;
  height: 180px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>